import React from 'react';

function NotFoundPage() {
  return (
    <div>
      <p>Page not found</p>
    </div>
  )
}


export default NotFoundPage
